import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/ap'

export default {
  getAccountPayableList (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/accountPayableList${queryParams}`)
  },

  getAccountPayablePaid (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/accountPayablePaid${queryParams}`)
  },

  getProcessPaymentTransactions (params) {
    return httpClient.post(`${endpoint}/processPaymentTransactions`, params)
  },

  processPayment (params) {
    return httpClient.post(`${endpoint}/processPayment`, params)
  },

  getPaymentVoucher (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/paymentVoucher${queryParams}`)
  },

  showPaymentVoucher (idPv) {
    return httpClient.get(`${endpoint}/paymentVoucher/${idPv}`)
  },

  createPaymentVoucher (params) {
    return httpClient.post(`${endpoint}/paymentVoucher`, params)
  },

  getPaymentRequest (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/paymentRequest${queryParams}`)
  },

  showPaymentRequest (idPaymentRequest) {
    return httpClient.get(`${endpoint}/paymentRequest/${idPaymentRequest}`)
  },

  getPaymentRequestTransactions (idPaymentRequest) {
    return httpClient.get(`${endpoint}/paymentRequest/${idPaymentRequest}/transactions`)
  },

  createPaymentRequest (params) {
    return httpClient.post(`${endpoint}/paymentRequest`, params)
  },

  getPaymentHistory (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/paymentHistory${queryParams}`)
  }
}
